import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProviders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/providers`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProvider(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/provider/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addProvider(ctx, userData) {
            return new Promise((resolve, reject) => {

                let formData = new FormData();

                formData.append("auth", userData.auth);
                formData.append("business_area_id", userData.business_area.id);
                formData.append("tariff_id", userData.tariff && userData.tariff.id ? userData.tariff.id : null);
                formData.append("photo", userData.photo);

                formData.append("name", userData.name);
                formData.append("ruc", userData.ruc);
                formData.append("sdcISO", userData.sdcISO);
                formData.append("contract_numbers", userData.contract_numbers);
                formData.append("email", userData.email);
                //formData.append("password", userData.password);

                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/provider/create`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateProvider(ctx, { id, providerData }) {
            return new Promise((resolve, reject) => {

                let formData = new FormData();

                formData.append("accessId", providerData.id);
                formData.append("business_area_id", providerData.business_area_id);
                formData.append("tariff_id", providerData.tariff_id);
                formData.append("photo", providerData.photo);
                formData.append("changePhoto", providerData.changePhoto);
                formData.append("initPhoto", providerData.initPhoto);

                formData.append("name", providerData.name);
                formData.append("ruc", providerData.ruc);
                formData.append("start_date_cycle", providerData.start_date_cycle);
                formData.append("contract_numbers", providerData.contract_numbers);
                formData.append("email", providerData.email);
                formData.append("password", providerData.password);

                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/provider/${id}/update`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProvider(ctx, { id, auth }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/provider/${id}/delete?auth=${auth}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}